<template>
<div class="">
  <div class="flex border-b border-blue-500 pb-2 mb-4">
    <div class="grid sm:grid-cols-2 md:grid-cols-4 grid-cols-1 w-full">
      <div class="sm:col-span-2 md:col-span-4 mb-6 mt-1">
          <label :class="`${obtenerClaseEstado(ot.estadoLog)} text-lg font-medium py-4 px-12 rounded-full`">{{ ot.estadoLog }}</label>
      </div>
      <div class="text-left relative">
          <label class="text-lg font-medium text-gray-600">Fecha:</label>
      </div>
      <div class="text-lg font-medium text-gray-800 text-left">
      {{ ot.fechaCreacion }}
      </div>
      <div class="text-left relative">
          <label class="text-lg font-medium text-gray-600">Hora:</label>
      </div>
      <div class="text-lg font-medium text-gray-800 text-left relative">
      {{ ot.horaCreacion }}
      </div>
      <div class="text-left relative">
          <label class="text-lg font-medium text-gray-600">Nombre cliente:</label>
      </div>
      <div class="text-lg font-medium text-gray-800 text-left relative">
      {{ ot.nombre }}
      </div>
      <div class="text-left relative">
          <label class="text-lg font-medium text-gray-600">Apellidos:</label>
      </div>
      <div class="text-lg font-medium text-gray-800 text-left relative">
      {{ `${ot.apellidoPaterno} ${ot.apellidoMaterno}` }}
      </div>
      <div class="text-left relative">
          <label class="text-lg font-medium text-gray-600">Observación:</label>
      </div>
      <div class="text-lg font-medium text-gray-800 text-left relative">
          {{ ot.observacion }}
      </div>
      <div class="text-left relative">
          <label class="text-lg font-medium text-gray-600">Laboratorio:</label>
      </div>
      <div class="text-lg font-medium text-gray-800 text-left relative">
          {{ ot.laboratorio }}
      </div>
    </div>    
  </div>
  <h2 class="text-xl text-blue-700">Lentes solicitados</h2>
  
  <div class="shadow rounded-lg overflow-x-auto">
    <table class="table-auto w-full text-base divide-y divide-gray-200 whitespace-no-wrap overflow-hidden">
      <thead class="bg-blue-500 text-white">
        <tr>
          <th class="py-1 px-2">Lente</th>
          <th class="py-1 px-2">Distancia</th>
          <th class="py-1 px-2">E.D</th>
          <th class="py-1 px-2">C.D</th>
          <th class="py-1 px-2">Grd.D</th>
          <th class="py-1 px-2">E.I</th>
          <th class="py-1 px-2">C.I</th>
          <th class="py-1 px-2">Grd.I</th>
          <th class="py-1 px-2">Armazón</th>
          <th class="py-1 px-2">Filtro</th>
          <th class="py-1 px-2">Cristal</th>
          <th class="py-1 px-2">T.multi</th>
          <th class="py-1 px-2">DP</th>
          <th class="py-1 px-2">Valor</th>
        </tr>
      </thead>
      <tbody class="">
        <tr v-for="(lente,indice) in ot.detalleOT" :key="indice">
            <td class="py-1 px-2 text-left border border-gray-200">{{ lente.tipoLente }}</td>
            <td class="py-1 px-2 text-left border border-gray-200">{{ lente.tipoDistancia }}</td>
            <td class="py-1 px-2 text-right border border-gray-200">{{ lente.esfericoOd }}</td>
            <td class="py-1 px-2 text-right border border-gray-200">{{ lente.cilindricoOd }}</td>
            <td class="py-1 px-2 text-right border border-gray-200">{{ lente.gradosOd }}</td>
            <td class="py-1 px-2 text-right border border-gray-200">{{ lente.esfericoOi }}</td>
            <td class="py-1 px-2 text-right border border-gray-200">{{ lente.cilindricoOi }}</td>
            <td class="py-1 px-2 text-right border border-gray-200">{{ lente.gradosOi }}</td>
            <td :title="lente.armazon" class="py-1 px-2 text-left border border-gray-200">
              {{ `${lente.armazon.slice(0,6)}${lente.armazon.length > 6 ? '...' : ''}` }}
              </td>
            <td :title="lente.tipoFiltro" class="py-1 px-2 text-left border border-gray-200">
              {{ `${lente.tipoFiltro.slice(0,6)}${lente.tipoFiltro.length > 6 ? '...' : ''}` }}
              </td>
            <td class="py-1 px-2 text-left border border-gray-200">{{ lente.tipoCristal == 'Policarbonato' ? 'Poli.' : lente.tipoCristal }}</td>
            <td :title="lente.tipoMultifocal" class="py-1 px-2 text-left border border-gray-200">
              {{ `${lente.tipoMultifocal.slice(0,6)}${lente.tipoMultifocal.length > 6 ? '...' : ''}` }}</td>
            <td class="border border-gray-200 py-1 px-2">{{ lente.dp }}</td>
            <td class="text-right py-1 px-2 border border-gray-200">{{ this.formatearCifra(lente.valorLente) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div class="sm:inline-flex py-6">
    <div class="flex mx-2">
      <div class="px-2 whitespace-no-wrap">
        <label class="text-xl font-medium text-gray-700">Valor total:</label>
      </div>
      <div class="px-2 w-full text-right whitespace-no-wrap">
        <label class="text-xl font-medium text-gray-600">{{ `$ ${this.formatearCifra(ot.valorTotal)}` }}</label>
      </div>
    </div>
    <div class="flex mx-2">
      <div class="px-2 whitespace-no-wrap">
        <label class="text-xl font-medium text-gray-700">Saldo:</label>
      </div>
      <div class="px-2 w-full text-right whitespace-no-wrap">
        <label class="text-xl font-medium text-blue-600">{{ `$ ${this.formatearCifra(ot.saldo)}` }}</label>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { separarPorMiles } from '@/utils/helpers'

export default {
  name: 'DetalleOT',
  props: {
    ot: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      obtenerClaseEstado(key) {
      switch (key) {
        case 'Finalizada':
           return 'bg-gray-300 text-gray-800';

        case 'En espera de retiro':
          return 'bg-green-200 text-green-800';

        case 'Montaje OK':
          return 'bg-yellow-400 text-yellow-800';

        case 'Recibida en Montajes':
          return 'bg-blue-300 text-blue-800'; 

        case 'Despachada a Montajes':
          return 'bg-blue-300 text-blue-800';

        case 'Crédito simple':
          return 'bg-purple-300 text-purple-800';

        case 'Cristales solicitados':
          return 'bg-blue-300 text-blue-800';

        case 'En inicio':
          return 'bg-blue-300 text-blue-800';

        case 'Montaje fallido':
          return 'bg-red-300 text-red-700';

        case 'Recibida con error':
          return 'bg-red-300 text-red-700';
      
        default:
          break;
      }
    }
    }
  },
  methods: {
    formatearCifra(valor) {
            return separarPorMiles(valor);
    }
  },
  components: {
    
  },
  mounted() {
    
  }
}
</script>